import React from 'react';
import AboutSection from '../components/sections/AboutSection';
import Header from '../components/Header';
import ServicesSection from '../components/sections/ServicesSection';
import PortfolioSection from '../components/sections/PortfolioSection';
import ContactSection from '../components/sections/ContactSection';
import FixedRightCredit from '../components/RightFixedCredit';
import LeftFixedLinks from '../components/LeftFixedLinks';

const Portfolio = () => {
	return (
		<>
			<Header />
			<main>
				<PortfolioSection />
				<ServicesSection />
				<AboutSection />
				<ContactSection />
				<FixedRightCredit />
				<LeftFixedLinks />
			</main>
		</>
	);
};

export default Portfolio;