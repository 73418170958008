import React, {useState} from 'react';
import Typewriter from 'typewriter-effect';
import styled from 'styled-components';

const StyledDiv = styled.div`
	width: 23rem;
	padding-left: 4rem;
	height: 4rem;
	color: white;
	font-size: 4rem;
	line-height: 3rem;
	transition: 0.5s;

	@media (min-width: 1024px) {
		&.changeSize {
			width: 19rem;
			padding-left: 0;
			font-size: 2rem;
			transition: 0.5s;
		}
	}

	@media (max-width: 1024px) {
		font-size: 4rem;
		margin: 3rem 0;
		padding: 0;
		width: unset;
	}

	@media (max-width: 1024px) {
		font-size: 2.5rem;
	}
`;

const TypewriterComponent = () => {
	const [addClass, setAddClass] = useState(false);

	window.addEventListener('scroll', () => {
		if(window.pageYOffset > 150) {
			setAddClass(true);
		} else{
			setAddClass(false);
		}
	});

	return (
		<StyledDiv className={addClass && 'changeSize'}>
			<Typewriter 
				options={{
					strings: [
						'Hello!', 
						'你好!<br/><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">Nǐ hǎo!</span>',
						'Hola!',
						'नमस्ते!<br><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">Namaste!</span>',
						'Bonjour!',
						'أهلا!<br><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">\'ahla!</span>',
						'Olá!',
						'হ্যালো!<br><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">Hyālō!</span>',
						'Halo!',
						'Привет!<br><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">Privet!</span>',
						'Ciao!',
						'こんにちは!<br><span style="font-size: 1.8rem; color: rgba(255, 255, 255, .6);">Kon\'nichiwa!<span>',
					],
					autoStart: true,
					loop: true,
					cursor: '_',
					pauseFor: 700
				}}
			/>
		</StyledDiv>
	);
};

export default TypewriterComponent;