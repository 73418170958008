import React, {useState, useRef} from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import Input from './form-controls/Input';
import MessageInput from './form-controls/Textfield';
import FormButton from './buttons/FormButton';
import {VscTriangleUp, VscTriangleRight} from 'react-icons/vsc';
import {RiMailCheckFill} from 'react-icons/ri';

const StyledForm = styled.form`

	button {
		margin: 0 auto;
	}

	.error {
		margin: 0 0 1rem .5rem;
		color: rgb(112, 4, 4);
		font-size: 1.2rem;

	}
`;

const StyledButton = styled.button`
	font-size: 1.1rem;
	margin-top: 1.5rem;
	cursor: pointer;
	border: none;
	background-image: linear-gradient(
    	to right,
   		rgb(112, 4, 4),
		rgb(112, 4, 4) 50%,
    	white 50%
	);
	background-size: 200% 100%;
	background-position: -100%;
	display: inline-block;
	padding: 5px 0;
	position: relative;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transition: all 0.3s ease-in-out;

	&::before {
		content: '';
		background: rgb(112, 4, 4);
		display: block;
		position: absolute;
		bottom: -3px;
		left: 0;
		width: 0;
		height: 3px;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		background-position: 0;

		&::before {
			width:100%;
		}
	}
`;

const ContactForm = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState(false);
	const [bodyErrorMessage, setBodyErrorMessage] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const form = useRef();

	const sendForm = (e) => {
		let numError = 0;
		e.preventDefault();

		if(name.trim().length == 0) {
			setNameErrorMessage(true);
			numError++;
		} else {
			setNameErrorMessage(false);
		}

		if(/.+@.+\..+/.test(email)) {
			setEmailErrorMessage(false);
		} else {
			numError++;
			setEmailErrorMessage(true);
		}

		if(message.trim().length == 0) {
			numError++;
			setBodyErrorMessage(true);
		} else {
			setBodyErrorMessage(false);
		}

		if (numError == 0) {
			emailjs.sendForm('ncdev', 'portfolio_template', form.current, 'loI5L9cBz88-W6SHO')
				.then((result) => {
					console.log(result.text);
					setFormSubmitted(true);
				}, (error) => {
					console.log(error.text);
				});
		}
	};

	return (
		formSubmitted 
			? 
			<>
				<p style={{textAlign: 'center', fontSize: '3rem', margin: '0', color:'white'}}>Your Inquiry Has Been Sent!</p>
				<p style={{textAlign: 'center'}}><RiMailCheckFill style={{color: 'rgb(112, 4, 4)', fontSize:'6rem'}}/></p>
				<p style={{width: '25rem', textAlign: 'center', margin: '0 auto'}}>I appreciate your interest and will get back to you within 48 business hours.</p>
				<p style={{margin: '0 auto', width: 'fit-content'}}><VscTriangleRight style={{color: 'rgb(112, 4, 4)', fontSize: '1.2rem', verticalAlign: 'middle'}} /> <StyledButton onClick={() => (setFormSubmitted(false), setMessage(''))}>Send Another Inquiry</StyledButton></p>
			</>
			:
			<StyledForm ref={form} onSubmit={sendForm}>
				<Input label='Name *' name='name' value={name} onChange={(e) => (setName(e.target.value))} />
				{nameErrorMessage && 
				<p className='error'>
					<VscTriangleUp style={{marginRight: '.5rem'}} />
					Please let me know how you would like to be addressed
				</p>
				}
				<Input label='Email *' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
				{emailErrorMessage && 
				<p className='error'>
					<VscTriangleUp style={{marginRight: '.5rem'}} />
					Please provide a valid email address
				</p>
				}
				<MessageInput value={message} onChange={(e) => setMessage(e.target.value)} />
				{bodyErrorMessage && 
				<p className='error'>
					<VscTriangleUp style={{marginRight: '.5rem'}} />
					Please enter a message
				</p>
				}
				<FormButton />
			</StyledForm>
	);
};

export default ContactForm;