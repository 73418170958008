import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const Input = (props) => {
	return (
		<TextField
			fullWidth
			name={props.name}
			label={props.label}
			variant="filled"
			value={props.value}
			onChange={props.onChange}
			InputProps={{ disableUnderline: true }}
			sx={{
				backgroundColor:'rgba(0, 0, 0, .2)',
				marginBottom: '2rem',
				'& .MuiFilledInput-root': {
					color: 'rgb(112, 4, 4)',
					borderBottom:'solid 2px rgba(255, 255, 255, .75)',
				},
				'& .Mui-focused': {
					color:'rgba(255, 255, 255, .75)',
					borderBottom:'solid 2px rgb(114, 4, 4)',
				},
				'& .MuiInputLabel-root': {
					color: 'rgba(255, 255, 255, .75)',
					borderBottom:'none',
				},
				'& .MuiInputLabel-root.Mui-focused': {
					color: 'rgb(112, 4, 4)',
					borderBottom:'none',
				}
			}}
		/>
	);
};

export default Input;

Input.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};