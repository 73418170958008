import React from 'react';
import {TbBrandHtml5, TbBrandCss3} from 'react-icons/tb';
import {FaSass, FaReact, FaNode, FaGitAlt} from 'react-icons/fa';
import {SiCsharp, SiJavascript, SiMysql, SiVisualstudiocode, SiAdobexd, SiBootstrap, SiMaterialui, SiJquery, SiPhp} from 'react-icons/si';
import {BsGearFill, BsGithub} from 'react-icons/bs';
import {BiTerminal, BiDevices} from 'react-icons/bi';

export const services = [
	{
		id: 0,
		label: 'HTML5',
		icon: <TbBrandHtml5 />
	},
	{
		id: 1, 
		label: 'CSS3',
		icon: <TbBrandCss3 />
	},
	{
		id: 2, 
		label: 'C#',
		icon: <SiCsharp />
	},
	{
		id: 3, 
		label: 'Sass',
		icon: <FaSass />

	},
	{
		id: 4,
		label: 'Javascript', 
		icon: <SiJavascript />
	},
	{
		id: 5,
		label: 'jQuery', 
		icon: <SiJquery/>
	},
	{
		id: 6,
		label: 'PHP', 
		icon: <SiPhp />
	},
	{
		id: 7, 
		label: 'React',
		icon: <FaReact />
	},
	{
		id: 8, 
		label: 'Bootstrap',
		icon: <SiBootstrap />
	},
	{
		id: 9, 
		label: 'Material-UI',
		icon: <SiMaterialui />
	},
	{
		id: 10,
		label: 'MySQL',
		icon: <SiMysql />
	}, 
	{
		id: 11, 
		label: 'Node.js',
		icon: <FaNode />
	}, 
	{
		id: 12, 
		label: 'Git',
		icon: <FaGitAlt />
	}, 
	{
		id: 13,
		label: 'GitHub',
		icon: <BsGithub />
	}, 
	{
		id: 14,
		label: 'RESTful APIs',
		icon: <BsGearFill />
	},
	{
		id: 15,
		label: 'Command Line',
		icon: <BiTerminal />
	},
	{
		id: 16,
		label: 'VSCode',
		icon: <SiVisualstudiocode />
	}, 
	{
		id: 17, 
		label: 'Adobe XD',
		icon: <SiAdobexd />
	},
	{
		id: 18, 
		label: 'Responsive Design',
		icon: <BiDevices />
	}
];