import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes  from 'prop-types';

const MessageInput = (props) => {
	return (
		<TextField
			id="filled-multiline-static"
			name='message'
			label="Message *"
			multiline
			fullWidth
			rows={6}
			variant="filled"
			value={props.value}
			onChange={props.onChange}
			InputProps={{ disableUnderline: true }}
			sx={{
				backgroundColor:'rgba(0, 0, 0, .2)',
				marginBottom: '2rem',
				'& .MuiFilledInput-root': {
					color: 'rgb(112, 4, 4)',
					borderBottom:'solid 2px rgba(255, 255, 255, .75)',
				},
				'& .Mui-focused': {
					color: 'rgba(255, 255, 255, .75)',
					borderBottom:'solid 2px rgb(112, 4, 4)',
				},
				'& .MuiInputLabel-root': {
					color: 'rgba(255, 255, 255, .75)',
					borderBottom:'none',
				},
				'& .MuiInputLabel-root.Mui-focused': {
					color: 'rgb(112, 4, 4)',
					borderBottom:'none',
				}
			}}
		/>
	);
};

export default MessageInput;

MessageInput.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};
