import React from 'react';
import styled from 'styled-components';
import photoPNG from '../images/profile-picture.jpg';
import photoWEBP from '../images/profile-picture.webp';

const HeadshotOutterWrapper = styled.div`
	position: relative; 
	top: 4rem;
	width: 18rem;
	height: 22.7rem;
	box-shadow: 0 .5rem 1.5rem rgba(112, 4, 4, .9);
	border: .10rem solid silver;
	padding: .2rem;
	margin-right: 8rem;

	@media (max-width: 1024px) {
		margin: 0 auto;
	}

	@media (max-width: 768px) {
		width: 15rem;
		height: 18.9rem;
	}

	span {
		position: absolute;
		top: 0;
		height: 100%;

		&:nth-child(1) {
		 	transform:rotate(0deg);
			left: 0;
			width: 100%;
		}

		&:nth-child(2) {
			transform:rotate(90deg);
			left: 0;
			width: 70%;
		}

		&:nth-child(3) {
			transform:rotate(180deg);
			left: 0;
			width: 100%;
		}

		&:nth-child(4) {
			transform:rotate(270deg);
			left: 5.2rem;
			width: 70%;

			@media (max-width: 768px) {
				left: 4.4rem;
			}
		}

		&::before {
			content: '';
			position: absolute;
			width:100%;
			height: .4rem;
			background: rgb(112, 4, 4);
			animation: animate 4s linear infinite;
		}

		@keyframes animate {
			0% {
				transform:scaleX(0);
				transform-origin: left;
			}
			50% {
				transform:scaleX(1);
				transform-origin: left;
			}
			50.1% {
				transform:scaleX(1);
				transform-origin: right;
			}
			100% {
				transform:scaleX(0);
				transform-origin: right;
			}
		} 
	}
`;

const HeadshotInnerWrapper = styled.div`
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 20px;
	border: .18rem solid rgb(16, 16, 16);
	box-shadow: 0 5px 10px rgba(9,0,0,0.5);
`;

const StyledImg = styled.img`
	width: 100%;
	object-fit: contain;
`;

const Headshot = () => {
	return (
		<HeadshotOutterWrapper>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<picture>
				<source srcSet={photoWEBP} />
				<StyledImg src={photoPNG} alt='Nicollete Coney headshot' />
			</picture>
			<HeadshotInnerWrapper>
			</HeadshotInnerWrapper>
		</HeadshotOutterWrapper>
	);
};

export default Headshot;
