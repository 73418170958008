import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import NavBar from './nav/NavBar';
import TypewriterComponent from './TypeWriter';
import bitmojiJPG from '../images/bitmoji.jpg';
import bitmojiWEBP from '../images/bitmoji.webp';

const NavWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
  	width: calc(100% - 8rem);
	padding: 1.5rem 4rem;
  	position: fixed;
  	left: 0;
  	z-index: 4;
	background-color: rgba(16, 16, 16, .9);
	backdrop-filter: blur(6px);

	@media (max-width: 1024px) {
		padding: 1.5rem 2rem;
		width: calc(100% - 2rem);
	}
`;

const GreetingBkg = styled.div`
	background-size: cover;
	position: fixed;
	width: 100%;
	z-index: 3;

	@media (max-width: 1024px) {
		position: relative;
	}
`;

const GreetingDiv = styled.div`
	padding: 5rem 5rem 6rem 5rem;
	background-color: rgba(16, 16, 16, .8);
	text-align: center;
	backdrop-filter: blur(6px);
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	&.divider::before {
		content:'';
		font-family:'shape divider from ShapeDividers.com';
		position: absolute;
		bottom: -1px;
		left: -1px;
		right: -1px;
		top: -1px;
		background-repeat: no-repeat; 
		background-size: 100% 90px;
		background-position: 50% 100%;    
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23101010"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23101010"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23101010"/></svg>'); 
	}

	@media (max-width: 1024px) {
		display: block;
	}
`;

const StyledImage = styled.img`
	width: 18rem;

	@media (min-width: 1024px) {
		&.changeSize {
			width: 15rem;
			transition: 0.5s;
		}
	}

	@media (max-width: 1024px) {
		width: 12rem;
	}
`;

const GreetingH1 = styled.h1`
	word-spacing: -1rem;
	transition: 0.5s;

	@media (min-width: 1024px) {
		&.changeSize {
			font-size: 4rem;
			transition: 0.5s;
		}
	}

	@media (max-width: 1024px) {
		font-size: 3rem;
		margin-top: 1rem;
	}
`;

const GreetingH2 = styled.h2`
	margin: 0 0 2rem 0;
	font: 400 1.8rem 'Dosis', sans-serif;
	color: rgba(255, 255, 255, .75);
	line-height: 0;
	transition: 0.5s;

	@media (min-width: 1024px) {

		&.changeSize {
			font-size: 1.2rem;
			margin-bottom: .5rem;
			transition: 0.5s;
		}
	}

	@media (max-width: 1024px) {
		font-size: 1.2rem;
		line-height: 1rem;
	}
`;


const Header = () => {
	const [addClass, setAddClass] = useState(false);

	window.addEventListener('scroll', () => {
		if(window.pageYOffset > 150) {
			setAddClass(true);
		} else {
			setAddClass(false);
		}
	});
	
	return (
		<header>
			<NavWrapper>
				<Logo />
				<nav>
					<NavBar />
				</nav>
			</NavWrapper>
			<GreetingBkg>
				<GreetingDiv className='divider'>
					<TypewriterComponent />
					<picture>
						<source srcSet={bitmojiWEBP} />
						<StyledImage src={bitmojiJPG} alt='Nicollete Coney bitmoji' className={addClass && 'changeSize'} />
					</picture>
					<div>
						<GreetingH1 className={addClass && 'changeSize'}>{'I\'m Nicollete!'}</GreetingH1>
						<GreetingH2 className={addClass && 'changeSize'}>Full-Stack Web Developer</GreetingH2>
					</div>
				</GreetingDiv>
			</GreetingBkg>
		</header>
	);
};

export default Header;