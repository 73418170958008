import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonDiv = styled.div`
	display: flex;
  	justify-content: flex-start;
  	align-items: center;
	margin-top: 2rem;

	a {
		display: flex;
  		align-items: center;
		padding: .5rem 1.2rem;
		color: rgb(112, 4, 4);

		text-decoration: none;
		border: 1px solid rgb(112, 4, 4);
		position: relative;
		overflow: hidden;
		transition: transform .2s ease-in-out;
		transition: box-shadow .2s ease-in-out;

		&:hover {
			box-shadow: 1px 1px 25px 10px rgba(112, 4, 4, 0.4);

			&::before {
				left: 100%;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -100%;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				120deg,
				transparent,
				rgba(255, 255, 255, 0.2),
				transparent
			);
			transition: all 650ms;
		}

		&:active {
			transform: scale(98%);
			box-shadow: .5px .5px 15px 5px rgba(112, 4, 4, 0.4);
		}

		@media (max-width: 1024px) {
			font-size: 1.2rem;
			padding: .2rem .5rem;
			margin: 0 .5rem;
		}
	}

	button {
		display: flex;
  		align-items: center;
		font-family: Dosis;
		line-height: 1.5;
		padding: .5rem 1.2rem;
		color: rgb(112, 4, 4);
		background-color: transparent;
		font-size: inherit;
		text-transform: capitalize;
		text-decoration: none;
		border: 1px solid rgb(112, 4, 4);
		position: relative;
		overflow: hidden;
		transition: transform .2s ease-in-out;
		transition: box-shadow .2s ease-in-out;

		&:hover {
			cursor: pointer;
			box-shadow: 1px 1px 25px 10px rgba(112, 4, 4, 0.4);

			&::before {
				left: 100%;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -100%;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				120deg,
				transparent,
				rgba(255, 255, 255, 0.2),
				transparent
			);
			transition: all 650ms;
		}

		&:active {
			transform: scale(98%);
			box-shadow: .5px .5px 15px 5px rgba(112, 4, 4, 0.4);
		}

		@media (max-width: 1024px) {
			font-size: 1.2rem;
			padding: .2rem .5rem;
			margin: 0 .5rem;
		}
	}
`;

const SquareButton = (props) => {
	return (
		<ButtonDiv className={props.className}>
			{props.download ?
				<a href={props.url} download={props.download}>{props.content} {props.icon}</a>
				:
				props.button ?
					<button onClick={props.onClick}>{props.content} {props.icon}</button>
					:
					<a href={props.url} target="_blank" rel="noreferrer">{props.content} {props.icon}</a>
			}
		</ButtonDiv>
	);
};

export default SquareButton;

SquareButton.propTypes = {
	url: PropTypes.string,
	content: PropTypes.string.isRequired,
	icon: PropTypes.object.isRequired,
	download: PropTypes.string,
	button: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string
};