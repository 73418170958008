import React from 'react';
import styled from 'styled-components';
import ContactForm from '../ContactForm';
import SectionHeading from '../SectionHeading';

const ContactSectionWrapper = styled.section`
	margin: 8rem 13rem 3rem 13rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	#one {
		width: 100%;
	}

	#two {
		width: 35%;
		margin-left: 4rem;

		@media (max-width: 1024px) {
			font-size: 1.2rem;
			text-align: justify;
			width: 80%;
			margin-left: 1.5rem;

		}
	}

	#three {
		width: 45%;
		margin-right: 4rem;

		@media (max-width: 1024px) {
			width: 95%;
			margin: 2rem .5rem 0 .5rem;
		}
	}

	@media (max-width: 1024px) {
		margin: 10rem 3rem 2rem 3rem;
	}
`;


const ContactSection = () => {
	return (
		<ContactSectionWrapper id='contact'>
			<div id='one'>
				<SectionHeading content={'I\'m Available!'} />
			</div>
			<div id='two'>
				<p>{'I\'m currently accepting new clients! Have a project in mind or simply want to say hello? Feel free to reach out for any reason. I\'d love to hear from you!'}</p>
				<p>
					Please be sure to fill out the entire form and include all necessary project details and requirements to ensure timely communication.
				</p>
			</div>
			<div id='three'>
				<ContactForm />
			</div>
		</ContactSectionWrapper>
	);
};

export default ContactSection;