import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Ul = styled.ul`
	margin: 0;
	list-style: none;
	display: flex;
	flex-flow: row nowrap;

	li {
		padding: 0 0 0 2rem;
		cursor: pointer;
		color: white;
	}

  	@media (max-width: 1024px) {
		padding: 3rem 1.5rem 0 2rem;
		flex-flow: column nowrap;
		background-color: rgb(16, 16, 16);
		position: fixed;
		top: 0;
		right: 0;
		height: 35rem;
		width: 10rem;
		transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
		transition: transform 0.3s ease-in-out;

		li {
			padding-top: 4rem;
		}
  	}
`;

const StyledLink = styled(Link)`
	font-size: 1.6rem;

	&:hover, &.active {
		color: rgb(16, 16, 16);
		letter-spacing: .15rem;
		text-shadow:
			-.5px .5px 0 red,
        	.5px .5px 0 silver,
        	.5px -.5px 0 darkred,
        	-.5px -.5px 0 whitesmoke
		;

		@media not all and (min-resolution:.001dpcm) { 
			@supports (-webkit-appearance:none) {
				text-shadow:
					-1px 1px 0 darkred,
        			1px 1px 0 silver,
        			1px -1px 0 darkred,
        			-1px -1px 0 silver
				;
			}
		}

		&::before {
			content: '/';
		}

		&::after {
			content: '/'
		}
	};
`;

const NavList = ({ open }) => {
	return (
		<Ul open={open}>
			<li>
				<StyledLink to='portfolio' activeClass='active' spy={true} smooth={true} offset={-450} duration={500}>portfolio</StyledLink>
			</li>
			<li>
				<StyledLink to='services' activeClass='active' spy={true} smooth={true} offset={-450} duration={500}>services</StyledLink>
			</li>
			<li>
				<StyledLink to='about' activeClass='active' spy={true} smooth={true} offset={-450} duration={500}>about</StyledLink>
			</li>
			<li>
				<StyledLink to='contact' activeClass='active' spy={true} smooth={true} offset={-450} duration={500}>contact</StyledLink>
			</li>
		</Ul>
	);
};

export default NavList;

NavList.propTypes = {
	open: PropTypes.bool
};
