import React from 'react';
import {Link} from 'react-scroll';
import styled from 'styled-components';
import SectionHeading from '../SectionHeading';
import SquareButton from '../buttons/SquareButton';
import Headshot from '../HeadShot';
import resume from '../../images/NicolleteConeyResume.pdf';
import {BiDownload} from 'react-icons/bi';
import {VscTriangleRight} from 'react-icons/vsc';

const AboutSectionWrapper = styled.section`
	margin: 6rem 13rem;
	display: flex;
	justify-content: space-evenly;

	@media (max-width: 1024px) {
		margin: 4rem 3rem;
	}

	@media (max-width: 768px) {
		display: block;
	}
`;

const StyledDiv = styled.div`
	p {
		margin: 1rem 10rem 0 4rem;

		a {
			cursor: pointer;
			border-bottom: .5rem solid 1px;
			background-image: linear-gradient(
    			to right,
   				rgb(112, 4, 4),
				rgb(112, 4, 4) 50%,
    			white 50%
			);
			background-size: 200% 100%;
			background-position: -100%;
			display: inline-block;
			padding: 5px 0;
			position: relative;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			transition: all 0.3s ease-in-out;

			&::before {
				content: '';
				background: rgb(112, 4, 4);
				display: block;
				position: absolute;
				bottom: -3px;
				left: 0;
				width: 0;
				height: 3px;
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				background-position: 0;

				&::before {
					width:100%;
				}
			}
		}

		@media (max-width: 1024px) {
			margin: 1rem 1.8rem 0 1.8rem;
			text-align: justify;
			font-size: 1.2rem;

		}
	}
`;

const StyledSquareButton = styled(SquareButton)`
	margin: 2.5rem 0 0 4rem;

	@media (max-width: 1024px) {
		margin: 1.5rem 0 0 1.4rem;
	}

	@media (max-width: 768px) {
		margin: 2rem 25%;
	}
`;

const AboutSection = () => {
	return (
		<AboutSectionWrapper id='about' >
			<StyledDiv>
				<SectionHeading content='I am' />
				<p>
					{'a coder who designs, so whether you\'ve already got a design team at hand or you\'ve got a project you want to run solo, you can rest assured nothing will get lost in translation from front to back. I am enthusiastic about taking complex projects and turning them into exceptional user experiences that live on the web. Majority of my work is done remotely, but if you\'re in the SoCal area and would rather get together in person, that\'ll work just as well! Got a project in mind and think that we\'d be a great fit? '} 
					<VscTriangleRight style={{margin: '0 .1rem', color: 'rgb(112, 4, 4', fontSize: '1.8rem', verticalAlign: 'middle'}} /> 
					<Link to='contact' spy={true} smooth={true} offset={-100} duration={500}>{'I\'d love to hear from you!'}</Link>
				</p>
				<StyledSquareButton url={resume} download='NicolleteConeyDevResume' content='Résumé' icon={<BiDownload style={{marginLeft: '.5rem'}}/>} />
			</StyledDiv>
			<div>
				<Headshot />
			</div>
		</AboutSectionWrapper>
	);
};

export default AboutSection;