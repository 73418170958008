import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const H2 = styled.h2`
	width: fit-content;
	margin: 0;
	color: white;
	font: 3.5rem 'Marck Script', cursive;
	background-image: linear-gradient(120deg, #700404, #121212);
  	background-repeat: no-repeat;
  	background-size: 100% 0.3em;
  	background-position: 0 75%;

	@media (max-width: 1024px) {
		font-size: 3rem;
	}
`;

const SectionHeading = (props) => {
	return (
		<H2>{props.content}</H2>
	);
};

export default SectionHeading;

SectionHeading.propTypes = {
	content: PropTypes.string.isRequired
};