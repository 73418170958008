import React from 'react';
import styled from 'styled-components';
import {BsGithub} from 'react-icons/bs';
import {BsLinkedin} from 'react-icons/bs';
import {MdEmail} from 'react-icons/md';

const StyledContact = styled.address`
	color: #700404;
	text-shadow: 3px 2px black;
	position: fixed;
	bottom: 0;
	left: 4rem;
	font-size: 0;
	writing-mode: vertical-rl;
	display: flex;
	align-items: center;
	white-space: nowrap;

	a {
		display: block;
		margin-bottom: 1.5rem;
		color: #700404;
		text-shadow: 3px 2px black;

		&:hover {
			color: white;
		}
	}

	&::after {
		content: '';
    	width: .1rem;
    	height: 5rem;
		background-color: #700404;
	}

	@media (max-width: 1024px) {
		left: 1rem;
	}
`;

const LeftFixedLinks = () => {
	return (
		<StyledContact> 
			<a href='https://github.com/nac0512' target="_blank" rel="noreferrer"><BsGithub style={{fontSize: '1.5rem'}} /> Github</a>
			<a href='https://www.linkedin.com/in/nicolleteconey' target="_blank" rel="noreferrer"><BsLinkedin style={{fontSize: '1.5rem'}} /> LinkedIn</a>
			<a href='mailto:hello@nicolleteconey.dev'><MdEmail style={{fontSize: '1.5rem'}}/> Email</a>
		</StyledContact>
	);
};

export default LeftFixedLinks;