import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Days Unfolded Images
import daysUnfoldedPNG0 from '../images/days-unfolded/landing-page.png';
import daysUnfoldedPNG1 from '../images/days-unfolded/horoscope-page.png';
import daysUnfoldedPNG2 from '../images/days-unfolded/new-event.png';
import daysUnfoldedPNG3 from '../images/days-unfolded/edit-event.png';
import daysUnfoldedPNG4 from '../images/days-unfolded/notes-page.png';
import daysUnfoldedPNG5 from '../images/days-unfolded/account-page.png';
import daysUnfoldedPNG6 from '../images/days-unfolded/login-page.png';
import daysUnfoldedPNG7 from '../images/days-unfolded/signup-page.png';
import daysUnfoldedWEBP0 from '../images/days-unfolded/landing-page.webp';
import daysUnfoldedWEBP1 from '../images/days-unfolded/horoscope-page.webp';
import daysUnfoldedWEBP2 from '../images/days-unfolded/new-event.webp';
import daysUnfoldedWEBP3 from '../images/days-unfolded/edit-event.webp';
import daysUnfoldedWEBP4 from '../images/days-unfolded/notes-page.webp';
import daysUnfoldedWEBP5 from '../images/days-unfolded/account-page.webp';
import daysUnfoldedWEBP6 from '../images/days-unfolded/login-page.webp';
import daysUnfoldedWEBP7 from '../images/days-unfolded/signup-page.webp';

// Coney Transports Images
import coneyPNG0 from '../images/coney-transports/landing-page.png';
import coneyPNG1 from '../images/coney-transports/about-page.png';
import coneyPNG2 from '../images/coney-transports/services-page.png';
import coneyPNG3 from '../images/coney-transports/testimonials-page.png';
import coneyWEBP0 from '../images/coney-transports/landing-page.webp';
import coneyWEBP1 from '../images/coney-transports/about-page.webp';
import coneyWEBP2 from '../images/coney-transports/services-page.webp';
import coneyWEBP3 from '../images/coney-transports/testimonials-page.webp';

//Free Recipes Images
import recipesPNG0 from '../images/free-recipes/landing-page.png';
import recipesPNG1 from '../images/free-recipes/search-page.png';
import recipesPNG2 from '../images/free-recipes/meal-page.png';
import recipesPNG3 from '../images/free-recipes/drink-page.png';
import recipesWEBP0 from '../images/free-recipes/landing-page.webp';
import recipesWEBP1 from '../images/free-recipes/search-page.webp';
import recipesWEBP2 from '../images/free-recipes/meal-page.webp';
import recipesWEBP3 from '../images/free-recipes/drink-page.webp';

//anban Images
import kanbanPNG0 from '../images/kanban-board/landing-page.png';
import kanbanPNG1 from '../images/kanban-board/new-task.png';
import kanbanPNG2 from '../images/kanban-board/edit-task.png';
import kanbanPNG3 from '../images/kanban-board/music-page.png';
import kanbanWEBP0 from '../images/kanban-board/landing-page.webp';
import kanbanWEBP1 from '../images/kanban-board/new-task.webp';
import kanbanWEBP2 from '../images/kanban-board/edit-task.webp';
import kanbanWEBP3 from '../images/kanban-board/music-page.webp';

const daysUnfoldedImages = [
	{
		urlPNG: daysUnfoldedPNG0,
		urlWEBP: daysUnfoldedWEBP0,
		alt: 'Mockup of Days Unfolded landing page'
	},
	{
		urlPNG: daysUnfoldedPNG1,
		urlWEBP: daysUnfoldedWEBP1,
		alt: 'Mockup of Days Unfolded horoscope page'
	},
	{
		urlPNG: daysUnfoldedPNG2,
		urlWEBP: daysUnfoldedWEBP2,
		alt: 'Mockup of Days Unfolded new task form'
	},
	{
		urlPNG: daysUnfoldedPNG3,
		urlWEBP: daysUnfoldedWEBP3,
		alt: 'Mockup of Days Unfolded edit task form'
	},
	{
		urlPNG: daysUnfoldedPNG4,
		urlWEBP: daysUnfoldedWEBP4,
		alt: 'Mockup of Days Unfolded notes page'
	},
	{
		urlPNG: daysUnfoldedPNG5,
		urlWEBP: daysUnfoldedWEBP5,
		alt: 'Mockup of Days Unfolded acount page'
	},
	{
		urlPNG: daysUnfoldedPNG6,
		urlWEBP: daysUnfoldedWEBP6,
		alt: 'Mockup of Days Unfolded login page'
	},
	{
		urlPNG: daysUnfoldedPNG7,
		urlWEBP: daysUnfoldedWEBP7,
		alt: 'Mockup of Days Unfolded signup page'
	}
];

const coneyTransportImages = [
	{
		urlPNG: coneyPNG0,
		urlWEBP: coneyWEBP0,
		alt: 'Mockup of Coney Transports landing page'
	},
	{
		urlPNG: coneyPNG1,
		urlWEBP: coneyWEBP1,
		alt: 'Mockup of Coney Transports about page'
	},
	{
		urlPNG: coneyPNG2,
		urlWEBP: coneyWEBP2,
		alt: 'Mockup of Coney Transports services page'
	},
	{
		urlPNG: coneyPNG3,
		urlWEBP: coneyWEBP3,
		alt: 'Mockup of Coney Transports testimonials page'
	}
];

const freeRecipesImages = [
	{
		urlPNG: recipesPNG0,
		urlWEBP: recipesWEBP0,
		alt: 'Mockup of Anyone Can Cook landing page'
	},
	{
		urlPNG: recipesPNG1,
		urlWEBP: recipesWEBP1,
		alt: 'Mockup of Anyone Can Cook search page'
	},
	{
		urlPNG: recipesPNG2,
		urlWEBP: recipesWEBP2,
		alt: 'Mockup of Anyone Can Cook meal page'
	},
	{
		urlPNG: recipesPNG3,
		urlWEBP: recipesWEBP3,
		alt: 'Mockup of Anyone Can Cook drink page'
	}
];

const kanbanImages = [
	{
		urlPNG: kanbanPNG0,
		urlWEBP: kanbanWEBP0,
		alt: 'Mockup of Kanban Board landing page'
	},
	{
		urlPNG: kanbanPNG1,
		urlWEBP: kanbanWEBP1,
		alt: 'Mockup of Kanban Board new task form'
	},
	{
		urlPNG: kanbanPNG2,
		urlWEBP: kanbanWEBP2,
		alt: 'Mockup of Kanban Board edit task form'
	},
	{
		urlPNG: kanbanPNG3,
		urlWEBP: kanbanWEBP3,
		alt: 'Mockup of Kanban Board music form'
	}
];

const StyledSpan = styled.span`
	color: rgb(112, 4, 4);
	font-weight: 700;
`;

const StyledImg = styled.img`
	width: 50rem;
	margin: 0 auto;
	object-fit: contain;
	filter: drop-shadow(-4px 12px 24px rgba(0, 0, 0, 1));

	@media (max-width: 1024px) {
		width: 90%;
		margin-left: .8rem;
	}
`;

const StyledP = styled.p`
	margin-top: 0;

	@media (max-width: 1024px) {
		font-size: 1.2rem;
	}
`;

const PortfolioModal = (props) => {
	return (
		<>
			<Modal
				open={props.daysUnfoldedOpen}
				onClose={props.daysUnfoldedClose}
				center={true}
				styles={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, .9)'
					},
					modal: {
						backgroundColor: 'rgba(16, 16, 16)'

					},
					closeButton: {
						backgroundColor: 'rgba(255, 255, 255, .75)',
						zIndex: 5
					}
				}}
			>
				<div className="slide-container">
					<Fade>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[0].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[0].urlPNG} alt={daysUnfoldedImages[0].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[1].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[1].urlPNG} alt={daysUnfoldedImages[1].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[2].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[2].urlPNG} alt={daysUnfoldedImages[2].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[3].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[3].urlPNG} alt={daysUnfoldedImages[3].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[4].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[4].urlPNG} alt={daysUnfoldedImages[4].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[5].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[5].urlPNG} alt={daysUnfoldedImages[5].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[6].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[6].urlPNG} alt={daysUnfoldedImages[6].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={daysUnfoldedImages[7].urlWEBP} />
								<StyledImg src={daysUnfoldedImages[7].urlPNG} alt={daysUnfoldedImages[7].alt} />
							</picture>
						</div>
					</Fade>
				</div>
				<StyledP>
					Days Unfolded is built for people who like to keep track of their thoughts and schedule tangibly, and want it all in the same place. A soft pink, sandy brown, and teal theme add a feminie and luxurious touch that helps to faciliate relaxation. 
				</StyledP>
				<StyledP>
					Some key features of this <StyledSpan>React App</StyledSpan> include a <StyledSpan>MySql Database</StyledSpan> served on the frontend and a <StyledSpan>Nodejs Server</StyledSpan> on the backend which help to promote cross-browser functionality. This also enables user creation and login capabilities, which allows multiple users to use the planner concurrently as well as create, save, edit, and delete their information. <StyledSpan>RESTful APIs</StyledSpan> and <StyledSpan>JavaScript</StyledSpan> also serve to present the user with real-time weather, time, daily quotes, and horoscope data whenever they access their personalized dashboard. <StyledSpan>Material-UI Components</StyledSpan> ensure this planner is fully responsive and can be acessed from any device.
				</StyledP>
			</Modal>

			<Modal
				open={props.coneyTransportsOpen}
				onClose={props.coneyTransportsClose}
				center={true}
				styles={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, .9)'
					},
					modal: {
						backgroundColor: 'rgba(16, 16, 16)'

					},
					closeButton: {
						backgroundColor: 'rgba(255, 255, 255, .75)',
						zIndex: 5
					}
				}}
			>
				<div className="slide-container">
					<Fade>
						<div className="each-fade">
							<picture>
								<source srcSet={coneyTransportImages[0].urlWEBP} />
								<StyledImg src={coneyTransportImages[0].urlPNG} alt={coneyTransportImages[0].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={coneyTransportImages[1].urlWEBP} />
								<StyledImg src={coneyTransportImages[1].urlPNG} alt={coneyTransportImages[1].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={coneyTransportImages[2].urlWEBP} />
								<StyledImg src={coneyTransportImages[2].urlPNG} alt={coneyTransportImages[2].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={coneyTransportImages[3].urlWEBP} />
								<StyledImg src={coneyTransportImages[3].urlPNG} alt={coneyTransportImages[3].alt} />
							</picture>
						</div>
					</Fade>
				</div>
				<StyledP>
					Coney Transports is a trucking company-style website built with various technologies, but prodiminately using <StyledSpan>HTML5</StyledSpan> and styled with <StyledSpan>CSS3.</StyledSpan> The purpose of this site is to provide information about the business itself as well as be a point of contact for those looking to work with the company, be it for hiring services or seeking employment. 
				</StyledP>
				<StyledP>
					Some key features of this project include a bold color palette consisting of red, black, and white to promote the company as reliable and passionate about what they do, and auto-scrolling links help to provide the user with easy navigation. <StyledSpan>JavaScript</StyledSpan> helped to create a carousel for testimonials so users can see the success others have had with the company and validate all forms to ensure the user is able to request information with no issues. <StyledSpan>Bootstrap</StyledSpan> ensured that this site is completely responsive so that users can visit and interact with it on any device.
				</StyledP>
			</Modal>

			<Modal
				open={props.freeRecipesOpen}
				onClose={props.freeRecipesClose}
				center={true}
				styles={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, .9)'
					},
					modal: {
						backgroundColor: 'rgba(16, 16, 16)'

					},
					closeButton: {
						backgroundColor: 'rgba(255, 255, 255, .75)',
						zIndex: 5
					}
				}}
			>
				<div className="slide-container">
					<Fade>
						<div className="each-fade">
							<picture>
								<source srcSet={freeRecipesImages[0].urlWEBP} />
								<StyledImg src={freeRecipesImages[0].urlPNG} alt={freeRecipesImages[0].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={freeRecipesImages[1].urlWEBP} />
								<StyledImg src={freeRecipesImages[1].urlPNG} alt={freeRecipesImages[1].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={freeRecipesImages[2].urlWEBP} />
								<StyledImg src={freeRecipesImages[2].urlPNG} alt={freeRecipesImages[2].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={freeRecipesImages[3].urlWEBP} />
								<StyledImg src={freeRecipesImages[3].urlPNG} alt={freeRecipesImages[3].alt} />
							</picture>
						</div>
					</Fade>
				</div>
				<StyledP>
					Anyone Can Cook is a <StyledSpan>React App</StyledSpan> that allows users to look up food and drink recipes by keyword categories. Food recipe keywords fall under cuisine, category such as vegan or seafood, or single keyword tags such as fish, tomato, breakfast or dessert. Drink recipe keywords fall under category such as shake or shot, alcoholic or non alcoholic, glass such as collins glass, mason jar, or wine glass, or single keyword inegredients such as wine, chocolate, or orange. Additionally, drink and meal recipes can be presented at random by pressing the respective random links for those wanting to try something new.
				</StyledP>
				<StyledP>
					This project was built and designed using <StyledSpan>HTML5</StyledSpan>, <StyledSpan>CSS3</StyledSpan>, and <StyledSpan>JavaScript</StyledSpan>. A soft green, white, and yellow color scheme serve to boast health, freshness, and happiness as the user looks for new and interesting recipes to try. The key feature of this project is its <StyledSpan>RESTful API</StyledSpan> functionality, as all the recipes and their information are loaded in from a third party when the user enters a search request, including links to YouTube videos of the food being made for those who are visual learners. 
				</StyledP>
			</Modal>

			<Modal
				open={props.kanbanOpen}
				onClose={props.kanbanClose}
				center={true}
				styles={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, .9)'
					},
					modal: {
						backgroundColor: 'rgba(16, 16, 16)'

					},
					closeButton: {
						backgroundColor: 'rgba(255, 255, 255, .75)',
						zIndex: 5
					}
				}}
			>
				<div className="slide-container">
					<Fade>
						<div className="each-fade">
							<picture>
								<source srcSet={kanbanImages[0].urlWEBP} />
								<StyledImg src={kanbanImages[0].urlPNG} alt={kanbanImages[0].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={kanbanImages[1].urlWEBP} />
								<StyledImg src={kanbanImages[1].urlPNG} alt={kanbanImages[1].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={kanbanImages[2].urlWEBP} />
								<StyledImg src={kanbanImages[2].urlPNG} alt={kanbanImages[2].alt} />
							</picture>
						</div>
						<div className="each-fade">
							<picture>
								<source srcSet={kanbanImages[3].urlWEBP} />
								<StyledImg src={kanbanImages[3].urlPNG} alt={kanbanImages[3].alt} />
							</picture>
						</div>
					</Fade>
				</div>
				<StyledP>
					This kanban board is perfect for tracking progress status for any size project. This board features a sleek greyscale theme conducive to productivity, and each board is color coded with a classic red, yellow, and  green side border to easily understand the level of completion for each task. Styling and updating the design with <StyledSpan>Sass</StyledSpan> allowed for seamless effeciency thanks to css variables, nesting, and mixins to minimize repetitive code.
				</StyledP>
				<StyledP>
					Some key features include <StyledSpan>RESTful API functionality</StyledSpan> to load in any created tasks, but also includes the capability to create, edit, and delete tasks as the user deems necessary. The board does go to sleep after a period of inactivity so as to not always be running in the background, and the user is presented with a dyanmic loading screen built by <StyledSpan>JavaScript</StyledSpan> to let them know their tasks are being imported in. Each task includes a standout title, as well as a description and due date to easily keep track of upcoming deadlines. A music toggle with five radio station options is included for those who prefer it to the silence. The music is <StyledSpan>persistent</StyledSpan>, meaning that even if the browser is exited, the board will remember the last station selected and automatically play it upon returning, as long as the browser settings permit auto-play capability. 
				</StyledP>
			</Modal>
		</>
	);
};

export default PortfolioModal;

PortfolioModal.propTypes = {
	coneyTransportsOpen: PropTypes.bool.isRequired,
	coneyTransportsClose: PropTypes.func.isRequired,
	freeRecipesOpen: PropTypes.bool.isRequired,
	freeRecipesClose: PropTypes.func.isRequired,
	kanbanOpen: PropTypes.bool.isRequired,
	kanbanClose: PropTypes.func.isRequired,
	daysUnfoldedOpen: PropTypes.bool.isRequired,
	daysUnfoldedClose: PropTypes.func.isRequired
};