import React from 'react';
import styled from 'styled-components';
import SectionHeading from '../SectionHeading';
import { services } from '../consts/services';

const ServicesSectionWrapper = styled.section`
	margin: 6rem 13rem;

	@media (max-width: 1024px) {
		padding-top: 2rem;
		margin: 0 3rem 4rem 3rem;
	}
`;

const StyledList = styled.ul`
	list-style: none;
	margin: 0 2.1rem;
	padding: 0;
	text-align: center;

	@media (max-width: 1024px) {
		margin: .5rem 0 0 1rem ;
	}
`;

const StyledListItem = styled.li`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	width: 5.5rem;
	margin: 2rem 3rem 2rem 0;
	color: rgb(112, 4, 4);
	font-size: 6rem;

	span {
		color: white;
		font-size: 1.6rem;
		margin-top: 1.5rem;
  		white-space: pre-line;

		@media (max-width: 1024px) {
			width: 1rem;
			font-size: 1rem;
		}
	}

	@media (max-width: 1024px) {
		font-size: 2.5rem;
		margin: 2rem 0 2rem 1rem;
		align-items: flex-start;
	}

	@media (max-width: 768px) {
		font-size: 1.8rem;
		margin: .5rem 0 .5rem .5rem;
		align-items: flex-start;
		width: 4.8rem;
	}
`;

const ServicesSection = () => {
	return (
		<ServicesSectionWrapper id='services'>
			<SectionHeading content='Techstack' />
			<StyledList>
				{services.map((service) => (
					<StyledListItem key={service.id}>
						{service.icon}
						<span>
							{service.label}
						</span>
					</StyledListItem>
				))}
			</StyledList>
		</ServicesSectionWrapper>
	);
};

export default ServicesSection;