import React from 'react';
import Portfolio from './pages/Portfolio';
import './modernizr';

function App() {
	return (
		<Portfolio />
	);
}

export default App;
