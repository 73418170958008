import React from 'react';
import styled from 'styled-components';
import {CgCopyright} from 'react-icons/cg';

const StyledP = styled.p`
	&.credit { 
		position: fixed;
		bottom: 0;
		right: 4rem;
		color: #700404;
		font-size: .8rem;
		font-style: inherit;
		letter-spacing: .1rem;
		writing-mode: vertical-rl;
		display: flex;
		align-items: center;
		white-space: nowrap;
		margin: 0;

		&::after {
			content: '';
			margin-top: 1.5rem;
			width: .1rem;
			height: 5rem;
			background-color: #700404;
		}

		@media (max-width: 1024px) {
			right: 1rem;
		}
	}
`;

const FixedRightCredit = () => {
	return (
		<StyledP className='credit'><CgCopyright style={{transform: 'rotate(90deg)', marginBottom: '.2rem'}}/> Nicollete Coney | 2022</StyledP>
	);
};

export default FixedRightCredit;