import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { TbBrandHtml5, TbBrandCss3 } from 'react-icons/tb';
import { SiBootstrap, SiJavascript, SiMysql, SiMaterialui} from 'react-icons/si';
import { BsGithub, BsChevronDoubleRight, BsGearFill } from 'react-icons/bs';
import { BiLinkExternal } from 'react-icons/bi';
import { FaReact, FaSass, FaNode } from 'react-icons/fa';
import SquareButton from '../buttons/SquareButton';
import PortfolioModal from '../PortfolioModal';
import coneyTransportsPNG from '../../images/coney-transports/coney-transports.png';
import coneyTransportsWEBP from '../../images/coney-transports/coney-transports.webp';
import freeRecipesPNG from '../../images/free-recipes/free-recipes.png';
import freeRecipesWEBP from '../../images/free-recipes/free-recipes.webp';
import kanbanPNG from '../../images/kanban-board/kanban-board.png';
import kanbanWEBP from '../../images/kanban-board/kanban-board.webp';
import daysUnfoldedPNG from '../../images/days-unfolded/days-unfolded.png';
import daysUnfoldedWEBP from '../../images/days-unfolded/days-unfolded.webp';

const PortfolioSectionWrapper = styled.section`
	margin: 0 13rem 4rem 13rem;
	padding-top: 31.5rem;

	.carousel-slider {
		height: 30rem;
	}

	.carousel .slide img {
		width: 37rem;
		height: auto;
		object-fit: contain;

		@media (max-width: 1024px) {
			width: 20rem;
		}
	}

	.carousel .slider-wrapper {
		height: 450px !important;
	}

	.carousel .slide {
		height: 28rem;

	}

	@media (max-width: 1024px) {
		padding-top: 1rem;
		margin: 0 3rem 4rem 3rem;
	}
`;

const ImgDiv = styled.div`
	margin-top: 2rem;
	width: fit-content;
	height: fit-content;
	overflow: hidden;
	border-radius: .5rem;
	box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 2px, rgba(255, 255, 255, 0.65) 0px 3px 2px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
`;

const ProjectWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	padding-top: 2rem
`;

const ProjectTitle = styled.p`
	color: white;
	font-size: 3rem;
	text-align: center;
	margin: 0;

	@media (max-width: 1024px) {
		font-size: 2.5rem;
	}
`;

const ProjectDescrip = styled.p`
	width: 38rem;
	margin: .5rem;

	@media (max-width: 1024px) {
		width: 15rem;
		margin: 0 auto;
		font-size: 1.2rem;
	}

	@media (max-width: 768px) {
		width: 15rem;
		margin: 0rem 5rem;
		font-size: 1.2rem;
	}
`;

const ProjectMadeWith = styled.p`
	color: white;
	font-size: 2.5rem;
	text-align: center;
	margin: 1rem .5rem;

	svg {
		margin-right: 1.7rem;
	}

	@media (max-width: 1024px) {
		font-size: 1.8rem;
		width: 12rem;
		margin: 1.2rem auto;
		
		svg {
			margin-right: .6rem;
		}
	}
`;

const ProjectLinks = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin: 0;

	@media (max-width: 1024px) {
		padding: 0 2.5rem;
	}
`;

const StyledSquareButton = styled(SquareButton)`
	margin: 0 0 .5rem 0;
`;

const PortfolioSection = () => {
	const [openConeyTransports, setOpenConeyTransports] = useState(false);
	const [openFreeRecipes, setOpenFreeRecipes] = useState(false);
	const [openKanbanBoard, setOpenKanbanBoard] = useState(false);
	const [openDaysUnfolded, setOpenDaysUnfolded] = useState(false);

	return (
		<PortfolioSectionWrapper id='portfolio'>
			<Carousel
				axis='vertical'
				autoPlay={true}
				infiniteLoop={true}
				showArrows={true}
				showStatus={false}
				showThumbs={false}
				swipeable={true}
				emulateTouch={true}
				centerMode={false}
			>
				<ProjectWrapper>
					<ImgDiv>
						<picture>
							<source srcSet={daysUnfoldedWEBP} />
							<img src={daysUnfoldedPNG} alt='Screenshot of Days Unfolded planner website' />
						</picture>
					</ImgDiv>
					<div>
						<ProjectTitle>Days Unfolded</ProjectTitle>
						<ProjectDescrip>
							Days Unfolded is a customizable digitial planner that allows users to keep track of upcoming events, lists, notes, and a daily budget.
						</ProjectDescrip>
						<ProjectMadeWith>
							<FaReact />
							<TbBrandHtml5 />
							<TbBrandCss3 />
							<SiJavascript />
							<SiMysql />
							<FaNode />
							<SiMaterialui />
							<BsGearFill />
						</ProjectMadeWith>
						<ProjectLinks>
							<StyledSquareButton button={true} content='Learn More' icon={<BsChevronDoubleRight style={{marginLeft: '.5rem'}} />} onClick={() => setOpenDaysUnfolded(true)} />
						</ProjectLinks>
					</div>
				</ProjectWrapper>

				<ProjectWrapper>
					<div>
						<ProjectTitle>Coney Transports</ProjectTitle>
						<ProjectDescrip>
						Coney Transports is a trucking company business site that serves to give information about the business as well as attract potential clients.
						</ProjectDescrip>
						<ProjectMadeWith>
							<TbBrandHtml5 />
							<TbBrandCss3 />
							<SiJavascript />
							<SiBootstrap />
						</ProjectMadeWith>
						<ProjectLinks>
							<StyledSquareButton button={true} content='Learn More' icon={<BsChevronDoubleRight style={{marginLeft: '.5rem'}} />} onClick={() => setOpenConeyTransports(true)} />
							<StyledSquareButton url='https://nac0512.github.io/Trucking-Company-Project/index.html#' content='Demo' icon={<BiLinkExternal style={{marginLeft: '.5rem'}} />} />
							<StyledSquareButton url='https://github.com/nac0512/Trucking-Company-Project' content='Code' icon={<BsGithub style={{marginLeft: '.5rem'}} />}/>
						</ProjectLinks>
					</div>
					<ImgDiv>
						<picture>
							<source srcSet={coneyTransportsWEBP} />
							<img src={coneyTransportsPNG} alt='Screenshot of Coney Transports trucking company website' />
						</picture>
					</ImgDiv>
				</ProjectWrapper>

				<ProjectWrapper>
					<ImgDiv>
						<picture>
							<source srcSet={freeRecipesWEBP} />
							<img src={freeRecipesPNG} alt='Screenshot of Anyone Can Cook free recipe site website' />
						</picture>
					</ImgDiv>
					<div>
						<ProjectTitle>Anyone Can Cook</ProjectTitle>
						<ProjectDescrip>
						Anyone Can Cook is a free recipe site that allows users to look up both food and drink recipes by ingredient, or view recipes at random selection.
						</ProjectDescrip>
						<ProjectMadeWith>
							<FaReact />
							<TbBrandHtml5 />
							<TbBrandCss3 />
							<SiJavascript />
							<BsGearFill />
						</ProjectMadeWith>
						<ProjectLinks>
							<StyledSquareButton button={true} content='Learn More' icon={<BsChevronDoubleRight style={{marginLeft: '.5rem'}} />} onClick={() => setOpenFreeRecipes(true)} />
							<StyledSquareButton url='https://nac0512.github.io/API-Recipe-Project/' content='Demo' icon={<BiLinkExternal style={{marginLeft: '.5rem'}} />} />
							<StyledSquareButton url='https://github.com/nac0512/API-Recipe-Project' content='Code' icon={<BsGithub style={{marginLeft: '.5rem'}} />}/>
						</ProjectLinks>
					</div>
				</ProjectWrapper>

				<ProjectWrapper>
					<div>
						<ProjectTitle>Kanban Board</ProjectTitle>
						<ProjectDescrip>
						This project is a kanban board style project that is used for keeping track of project progress. Users can add, update, and delete tasks as they see fit.
						</ProjectDescrip>
						<ProjectMadeWith>
							<TbBrandHtml5 />
							<FaSass />
							<SiJavascript />
							<BsGearFill />
						</ProjectMadeWith>
						<ProjectLinks>
							<StyledSquareButton button={true} content='Learn More' icon={<BsChevronDoubleRight style={{marginLeft: '.5rem'}} />} onClick={() => setOpenKanbanBoard(true)} />
							<StyledSquareButton url='https://nac0512.github.io/Kanban-Project/' content='Demo' icon={<BiLinkExternal style={{marginLeft: '.5rem'}} />} />
							<StyledSquareButton url='https://github.com/nac0512/Kanban-Project' content='Code' icon={<BsGithub style={{marginLeft: '.5rem'}} />}/>
						</ProjectLinks>
					</div>
					<ImgDiv>
						<picture>
							<source srcSet={kanbanWEBP} />
							<img src={kanbanPNG} alt='Screenshot of kanban board project website' />
						</picture>
					</ImgDiv>
				</ProjectWrapper>
			</Carousel>
			<PortfolioModal daysUnfoldedOpen={openDaysUnfolded} daysUnfoldedClose={() => setOpenDaysUnfolded(false)} />
			<PortfolioModal coneyTransportsOpen={openConeyTransports} coneyTransportsClose={() => setOpenConeyTransports(false)} />
			<PortfolioModal freeRecipesOpen={openFreeRecipes} freeRecipesClose={() => setOpenFreeRecipes(false)} />
			<PortfolioModal kanbanOpen={openKanbanBoard} kanbanClose={() => setOpenKanbanBoard(false)} />
		</PortfolioSectionWrapper>
	);
};

export default PortfolioSection;