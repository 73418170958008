import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const StyledLink = styled(Link)`
	color: rgb(16, 16, 16);
	font-family: 'Marck Script', cursive;
	text-shadow:
		-.5px .5px 0 red,
        .5px .5px 0 silver,
        .5px -.5px 0 darkred,
        -.5px -.5px 0 whitesmoke
	;

	@media not all and (min-resolution:.001dpcm) { 
		@supports (-webkit-appearance:none) {
			text-shadow:
				-1px 1px 0 darkred,
        		1px 1px 0 silver,
        		1px -1px 0 darkred,
        		-1px -1px 0 silver
			;
		}
	}
	
	display: block;
	width: 5rem;
	padding-top: .3rem;
	cursor: pointer;
	font-size: 3rem;
	line-height: .4;
	word-wrap: break-word;

	span {
		font-size: 1.3rem;
		font-family: 'Dosis', sans-serif;
		display: inline-block;
		letter-spacing: .8rem;
	}
`;

const Logo = () => {
	return (
		<p style={{margin: 0}}>
			<StyledLink to='portfolio' spy={true} smooth={true} offset={-450} duration={500}>NC<span>dev</span></StyledLink>
		</p>
	);
};

export default Logo;